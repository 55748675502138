<template>
  <div
    class="title"
    :style="{
      'background-image': `url(${imageUrl})`
    }"
  >
    <h1>{{ title }}</h1>
  </div>
</template>

<script>
export default {
  name: "TripHeroTile",
  props: {
    title: {
      type: String,
      default: "Trip Hero Tile"
    },
    imageUrl: {
      type: String,
      default: ""
    }
  }
};
</script>
