<template>
  <div>
    <ul class="trips-list">
      <li
        v-for="trip in trips"
        :key="trip.id"
        :style="{
          'background-image': `url(${trip.trip_tile})`
        }"
        :class="{ 'commuter-trip': trip.field_trip_type == 'commuter' }"
      >
        <router-link
          :to="{ path: trip.field_slug }"
          tag="a"
          class="desktop"
          @mouseover.native="toggleActiveMarker('on', trip.id)"
          @mouseleave.native="toggleActiveMarker('off', trip.id)"
        >
          <div class="overlay"></div>
          <h3>{{ trip.title }}</h3>
          <h4 v-if="trip.field_trip_type == 'recreational'">
            {{ `${trip.field_origin} to ${trip.field_destination}` }}
          </h4>
          <p v-if="trip.field_trip_type == 'recreational'">
            {{ Math.round(trip.field_length) }}
            <span>km</span>
          </p>
        </router-link>
        <router-link
          :to="{ path: trip.field_slug }"
          tag="a"
          class="mobile"
          @mouseover.native="toggleActiveMarker('on', trip.id)"
          @mouseleave.native="toggleActiveMarker('off', trip.id)"
        >
          <div class="overlay"></div>
          <h3>{{ trip.title }}</h3>
          <h4 v-if="trip.field_trip_type == 'recreational'">
            {{ `${trip.field_origin} to ${trip.field_destination}` }}
          </h4>
          <p v-if="trip.field_trip_type == 'recreational'">
            {{ trip.field_length }}
            <span>km</span>
          </p>
        </router-link>
      </li>
      <li class="featured suggest-a-trip">
        <div class="overlay"></div>
        <h3>
          <a href="" @click.prevent="openForm()">Suggest a trip</a>
        </h3>
      </li>
    </ul>
  </div>
</template>
<script>
/* eslint-disable no-undef */
import { mapState, mapMutations } from "vuex";
import {
  TRIP_FORM_OPEN,
  TOGGLE_MARKERS,
  SET_DIRECTIONS_RENDERER,
  SET_TRIP_DIRECTIONS,
  SET_TRIP_SUGGESTED_PATH,
  SET_SUGGEST_TRIP_FORM,
  SET_SUGGEST_TRIPWAY_A,
  SET_SUGGEST_TRIPWAY_B,
  SET_SUGGEST_TRIP_MARKERS,
  RESET_SUGGEST_TRIP_MARKERS,
  RESET_SUGGEST_TRIP_DATA,
  RESET_DIRECTIONS_RENDERER,
  RESTORE_ZOOM
} from "@/store/mutation-type";

export default {
  name: "TripSelector",
  props: {
    trips: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  watch: {
    // Reset suggest trip map and data if the trip form is closed.
    isTripFormOpened() {
      if (!this.isTripFormOpened) {
        let self = this;
        // Re enable the map markers
        this.toggleMarkers(true);

        // Remove temp markers
        this.resetSuggestTripMarkers();

        // Remove the event listeners
        google.maps.event.clearListeners(self.map, "click");
        google.maps.event.clearListeners(
          self.directionsRenderer,
          "directionsListener"
        );

        // Remove the directions
        this.resetDirectionsRenderer();
        this.resetSuggestTripData();

        // Reset form origin and destination inputs
        this.$parent.$children[1].$refs.origin.value = "";
        this.$parent.$children[1].$refs.destination.value = "";

        // Restore the map back to original look
        this.restoreZoom();
      }
    }
  },
  computed: {
    ...mapState("trip", [
      "map",
      "markers",
      "icon",
      "isTripFormOpened",
      "directionsRenderer",
      "suggestTripDirections",
      "suggestTripMarkers",
      "suggestTripWayA",
      "suggestTripWayB"
    ])
  },
  methods: {
    ...mapMutations("trip", {
      openTripForm: `${TRIP_FORM_OPEN}`,
      toggleMarkers: `${TOGGLE_MARKERS}`,
      setDirectionsRenderer: `${SET_DIRECTIONS_RENDERER}`,
      setTripDirections: `${SET_TRIP_DIRECTIONS}`,
      setTripSuggestedPath: `${SET_TRIP_SUGGESTED_PATH}`,
      setSuggestTripForm: `${SET_SUGGEST_TRIP_FORM}`,
      setSuggestTripwayA: `${SET_SUGGEST_TRIPWAY_A}`,
      setSuggestTripwayB: `${SET_SUGGEST_TRIPWAY_B}`,
      setSuggestTripMarkers: `${SET_SUGGEST_TRIP_MARKERS}`,
      resetSuggestTripMarkers: `${RESET_SUGGEST_TRIP_MARKERS}`,
      resetSuggestTripData: `${RESET_SUGGEST_TRIP_DATA}`,
      resetDirectionsRenderer: `${RESET_DIRECTIONS_RENDERER}`,
      restoreZoom: `${RESTORE_ZOOM}`
    }),
    openForm() {
      this.openTripForm();
      this.toggleMarkers(false);
      this.setUp();
    },
    setUp() {
      let self = this;

      if (this.isTripFormOpened) {
        let autocompleteOptions = {
          componentRestrictions: {
            country: "au"
          }
        };
        let originAutocomplete = new google.maps.places.Autocomplete(
          document.getElementById("suggestTripFormOrigin"),
          autocompleteOptions
        );
        let destinationAutocomplete = new google.maps.places.Autocomplete(
          document.getElementById("suggestTripFormDestination"),
          autocompleteOptions
        );

        // Events for detecting
        google.maps.event.addListener(
          originAutocomplete,
          "place_changed",
          function() {
            self.submitPath();
          }
        );

        google.maps.event.addListener(
          destinationAutocomplete,
          "place_changed",
          function() {
            self.submitPath();
          }
        );

        window.scrollTo(0, 0);
      }

      this.setDirectionsRenderer(
        new google.maps.DirectionsRenderer({
          draggable: true,
          map: this.map,
          polylineOptions: {
            strokeColor: "#FFD300",
            strokeOpacity: 0.9,
            strokeWeight: 5
          }
        })
      );

      // Listen for when the directions change
      google.maps.event.addListener(
        self.directionsRenderer,
        "directions_changed",
        function() {
          // Get the directions
          self.setTripDirections(self.directionsRenderer.getDirections());

          // Get each leg of the route
          let legs = self.suggestTripDirections.routes[0].legs[0];

          // Build the path
          let path = [];

          // Get the start position
          let startPosition = {
            lat: legs.start_location.lat(),
            lng: legs.start_location.lng()
          };

          path.push(startPosition);

          // Get the trip waypoints
          for (let i = 0; i < legs.via_waypoints.length; i++) {
            let waypoint = {
              lat: legs.via_waypoints[i].k,
              lng: legs.via_waypoints[i].B
            };
            path.push(waypoint);
          }

          // Get the end position
          let endPosition = {
            lat: legs.end_location.lat(),
            lng: legs.end_location.lng()
          };

          path.push(endPosition);

          // Stringify because the CMS expects a JSON string, not an object
          self.setTripSuggestedPath({
            start: JSON.stringify(startPosition),
            end: JSON.stringify(endPosition)
          });

          // Update the form fields
          self.$parent.$children[1].$refs.origin.value =
            self.suggestTripDirections.routes[0].legs[0].start_address;
          self.$parent.$children[1].$refs.destination.value =
            self.suggestTripDirections.routes[0].legs[0].end_address;
        }
      );

      // Enable clicking on the map to add a marker
      google.maps.event.addListener(self.map, "click", function(event) {
        // Don't allow the user to create more than 2 markers
        if (self.suggestTripMarkers.length == 2) {
          return false;
        }

        // First click, create the A (origin) marker
        if (!self.suggestTripWayA) {
          self.setSuggestTripwayA(
            new google.maps.Marker({
              position: event.latLng,
              map: self.map
            })
          );

          // Save the marker
          self.setSuggestTripMarkers(self.suggestTripWayA);
        } else {
          // Second click, create the B (destination) marker
          self.setSuggestTripwayB(
            new google.maps.Marker({
              position: event.latLng,
              map: self.map
            })
          );

          // Save the marker
          self.setSuggestTripMarkers(self.suggestTripWayB);

          // Calculate the route
          self.calculateRoute(
            self.suggestTripWayA.getPosition(),
            self.suggestTripWayB.getPosition()
          );
        }
      });
    },
    // Calculates the route on the Google map
    calculateRoute(a, b, isTextBasedSearch) {
      let self = this;
      // If the user is blah
      if (isTextBasedSearch) {
        google.maps.event.clearListeners(self.map, "click");
      }

      // Create the directions service
      let directionsService = new google.maps.DirectionsService();

      // Build the route
      directionsService.route(
        {
          origin: a,
          destination: b,
          travelMode: google.maps.DirectionsTravelMode.DRIVING
        },
        (response, status) => {
          if (status == "OK") {
            // Set the directions
            self.directionsRenderer.setDirections(response);

            // Remove the markers
            self.removeSuggestTripMarkers();

            // Stop people from adding more markers to the map
            google.maps.event.clearListeners(self.map, "click");
          } else {
            // TODO: We need a global ui error handler
            alert("Error: Unable to find directions for route!");
            self.resetSuggestTripMarkers();
            self.resetSuggestTripData();
          }
        }
      );
    },
    // Removes the temp markers
    removeSuggestTripMarkers() {
      // Loop over suggestTripMarkers markers, remove them so we can use the DirectionsRenderer markers
      this.suggestTripMarkers.forEach(marker => {
        marker.setMap(null);
      });
      this.resetSuggestTripMarkers();
    },
    submitPath() {
      // Calculate the route
      if (
        this.$parent.$children[1].$refs.origin.value !== "" &&
        this.$parent.$children[1].$refs.destination.value !== ""
      ) {
        this.calculateRoute(
          this.$parent.$children[1].$refs.origin.value,
          this.$parent.$children[1].$refs.destination.value,
          true
        );
      }
    },
    toggleActiveMarker(toggle, tripId) {
      if (toggle === "on") {
        this.markers.forEach(marker => {
          if (marker.id === tripId) {
            marker.setIcon(this.icon.active);

            if (typeof marker.infoWindow !== "undefined") {
              marker.infoWindow.open(this.map, marker);
            }
          } else {
            // reset all othr markers to default
            // marker.trip.active = false;
            marker.setIcon(this.icon.normal);
            marker.infoWindow.close(this.map, marker);
          }
        });
      } else {
        this.markers.forEach(marker => {
          marker.setIcon(this.icon.normal);
          marker.infoWindow.close(this.map, marker);
        });
      }
    }
  }
};
</script>
