<template>
  <div>
    <div class="thankyou" v-if="isTripFormSubmitted">
      <h3>Thank you</h3>
      <p>Your suggested route will be considered in future updates.</p>
      <router-link
        :to="{ name: 'trips' }"
        tag="a"
        @click.prevent.native="closeForm()"
        >View all trips</router-link
      >
    </div>
    <div v-if="!isTripFormSubmitted">
      <div class="top">
        <div class="overlay"></div>
        <button
          class="close"
          aria-label="Close"
          aria-describedby="descriptionClose"
          @click.prevent="closeForm()"
        >
          X
        </button>
        <div class="title">
          <h3>Suggest a trip</h3>
          <p>Got a great ride to share?</p>
        </div>
        <div id="descriptionClose" class="el-offscreen">
          Closing this form will discard any information entered and return you
          back to the main page
        </div>
      </div>
      <div class="wrapper">
        <form name="suggestTripForm" @submit.prevent="submitTrip()">
          <p class="intro">
            Fill in the details in the boxes below, and when we next update our
            list, your favourite road could be on it.
          </p>
          <p class="intro note">Note: <span>*</span> denotes mandatory field</p>
          <div class="form-item">
            <label for="suggest-a-trip-title">Title <span>*</span></label>
            <input
              required
              type="text"
              name="title"
              ref="title"
              id="suggest-a-trip-title"
              class="text"
              oninvalid="this.setCustomValidity('Please enter the title of this trip.')"
              oninput="setCustomValidity('')"
            />
          </div>
          <div class="form-item">
            <label for="suggest-a-trip-desc">Description <span>*</span></label>
            <textarea
              required
              oninvalid="this.setCustomValidity('Please enter a description of this trip.')"
              oninput="setCustomValidity('')"
              id="suggest-a-trip-desc"
              name="description"
              rows="4"
              cols="40"
              ref="description"
            ></textarea>
          </div>
          <div class="trip-directions">
            <div class="inner">
              <input
                required
                oninvalid="this.setCustomValidity('Please enter a starting point for this.')"
                oninput="setCustomValidity('')"
                type="text"
                id="suggestTripFormOrigin"
                name="origin"
                class="origin"
                ref="origin"
                placeholder="Enter a starting point, or use the map"
                title="Type to filter then select a starting point from list below"
              />
              <input
                required
                oninvalid="this.setCustomValidity('Please enter a destination for this trip.')"
                oninput="setCustomValidity('')"
                type="text"
                id="suggestTripFormDestination"
                name="destination"
                class="destination"
                ref="destination"
                placeholder="Enter a destination, or use the map"
                title="Type to filter then select the destination from list below"
              />
            </div>
          </div>
          <div class="submit">
            <input
              type="submit"
              value="Submit your trip"
              name="submit"
              class="submit-btn"
            />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import {
  TRIP_FORM_CLOSE,
  TOGGLE_MARKERS,
  SET_DIRECTIONS_RENDERER,
  RESTORE_ZOOM,
  TRIP_FORM_SUBMIT_END
} from "@/store/mutation-type";
import { SUGGEST_TRIP_CREATE } from "@/store/actions-type";

export default {
  name: "TripForm",
  computed: {
    ...mapState("trip", [
      "isTripFormOpened",
      "isTripFormSubmitted",
      "map",
      "suggestTripFormOrigin",
      "suggestTripFormDestination",
      "suggestTripDataStart",
      "suggestTripDataEnd"
    ])
  },
  methods: {
    closeForm() {
      this.closeTripForm();
      this.restoreZoom();
      this.tripFormSubmitEnd();
    },
    submitTrip() {
      this.submitTripSuggest({
        webform_id: "suggest_a_trip",
        title: this.$refs.title.value,
        description: this.$refs.description.value,
        starting_point:
          this.$refs.destination.value + " - " + this.suggestTripDataStart,
        destination_point:
          this.$refs.origin.value + " - " + this.suggestTripDataEnd
      });
    },
    ...mapActions("trip", {
      submitTripSuggest: `${SUGGEST_TRIP_CREATE}`
    }),
    ...mapMutations("trip", {
      closeTripForm: `${TRIP_FORM_CLOSE}`,
      toggleMarkers: `${TOGGLE_MARKERS}`,
      restoreZoom: `${RESTORE_ZOOM}`,
      setDirectionsRenderer: `${SET_DIRECTIONS_RENDERER}`,
      tripFormSubmitEnd: `${TRIP_FORM_SUBMIT_END}`
    })
  }
};
</script>
