<template>
  <div class="layout-trips full-height">
    <Preloader :loading="isLoading" />
    <aside
      id="trip-selector-side-panel"
      class="panel-wrapper col-sm-5 col-md-4 col-lg-3"
      :class="{ slideInLeft: !isLoading }"
    >
      <section
        class="panel panel-suggest-a-trip"
        :class="{ panelVisible: isTripFormOpened }"
      >
        <TripForm />
      </section>
      <section
        class="panel trip-selector"
        v-if="tripHero.title"
        :class="{ panelHidden: isTripFormOpened }"
      >
        <TripHeroTile
          :title="tripHero.title"
          :imageUrl="tripHero.heroImageUrl"
        />
        <TripSelector :trips="trips" />
      </section>
    </aside>
    <section aria-hidden="true" class="trip-map col-sm-7 col-md-8 col-lg-9">
      <GoogleMap
        name="TripsMap"
        :tripList="true"
        :mapConfig="mapConfig"
        :mapData="tripGeoData"
      />
    </section>
  </div>
</template>
<script>
import store from "@/store";
import { mapState, mapActions, mapGetters } from "vuex";
import { FETCH_TRIPS } from "@/store/actions-type";
import {
  GET_TRIPS_HERO,
  GET_TRIPS,
  GET_TRIPS_POI,
  GET_METADATA
} from "@/store/getters-type";
import {
  LOADING_START,
  LOADING_END,
  TRIP_FORM_CLOSE
} from "@/store/mutation-type";
import Preloader from "@/components/Preloader.vue";
import TripHeroTile from "@/components/TripHeroTile";
import TripSelector from "@/components/TripSelector";
import TripForm from "@/components/TripForm";
import GoogleMap from "@/components/GoogleMap";

export default {
  name: "Trips",
  components: { Preloader, TripSelector, TripHeroTile, GoogleMap, TripForm },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch(`trip/${FETCH_TRIPS}`);
    store.commit(`ui/${LOADING_START}`);
    next(vm => {
      vm.$store.commit(`ui/${LOADING_END}`);
      vm.$store.commit(`trip/${TRIP_FORM_CLOSE}`);
    });
  },
  metaInfo() {
    return {
      title: this.$route.meta.title,
      meta: this.getMeta
    };
  },
  computed: {
    ...mapState({
      isLoading: state => state.ui.isLoading,
      mapConfig: state => state.trip.mapConfig,
      isTripFormOpened: state => state.trip.isTripFormOpened
    }),
    ...mapGetters("trip", {
      tripHero: `${GET_TRIPS_HERO}`,
      trips: `${GET_TRIPS}`,
      tripGeoData: `${GET_TRIPS_POI}`,
      getMeta: `${GET_METADATA}`
    })
  },
  methods: {
    ...mapActions("trip", {
      fetchTrips: `${FETCH_TRIPS}`
    })
  }
};
</script>
